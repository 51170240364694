export function handleAxiosError(error, label = 'Failed request') {
    if (error.response) {
        console.error(label,
                      error.response.data,
                      error.response.status,
                      error.response.headers);
        let info = error.response.data;
        if ('err' in info) {
            info = info.err;
        }
        call(`${label}: ${info}`);
    } else if (error.request) {
        console.error(label, error.request);
        call(`${label}: ${error.request}`);
    } else {
        console.error('Error:', error.message);
        call(`${label}: ${error.message}`);
    }
}

function call(str){
    if(typeof str === 'string')
        toastr.error(str);
}

export function isUnauthorizedError(error) {
  return typeof error === 'object' &&
    'response' in error &&
    error.response.status === 401;
}
